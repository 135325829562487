/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { bindActionCreators } from "redux";

import Input from "../../../../../common/input/Input";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import { changeMoeda } from '../../../../../../actions/patrimonio/PatrimonioAction';
import MaskedInput from "../../../../../common/input/MaskedInput";
import Button from "../../../../../common/button/Button";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import { formatDiaMes } from "../../../../../../utils/helpers/StringHelper";

let PatrimonioFormGeral = (props) => {
  const {
    readOnly,
    isAdmin,
    isGestor,
    view,
    emissores,
    naturezas,
    titulos,
    produtosPassivos,
    series,
    tipoEmpresas,
    regimeTributarios,
    usuariosGestaoList,
    usuariosServicoCreditoList,
    patrimonioSeparado,
    isTreasury,
  } = props;

  const empresasList = tipoEmpresas?.filter((tipo) => tipo.key == 3 || tipo.key == 4) || [];

  const statusValue = patrimonioSeparado?.statusAprovacao?.key;

  const novo = /novo/.test(window.location.href);

  const addContaArrecadadora = (index) => {
    props.arrayInsert("patrimonioForm", "series", index, {});
  }

  const removeContaArrecadadora = (index) => {
    props.arrayRemove("patrimonioForm", "series", index);
  }

  React.useEffect(() => {
    if (!series.length) addContaArrecadadora(series.length)
  }, [series])

  let patrimonioHeader = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Patrimônio Separado</span>
        <div className="row">
          <div className="col-lg-6">
            <Field
              component={Input}
              inputId="descricao"
              name="descricao"
              type="text"
              label="Nome do Patrimônio Separado"
              readOnly={true}
              required={!novo}
            />
          </div>

          <div className="col-lg-6">
            <Field
              component={Input}
              inputId="apelido"
              name="apelido"
              type="text"
              label="Apelido"
              readOnly={readOnly}
              required
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="emissor"
              name="emissor"
              label="Emissor"
              options={emissores}
              textField="descricaoComCnpj"
              valueField="id"
              readOnly={readOnly}
              filter="contains"
              required
            />
          </div>

          <div className="col-lg-2">
            <Field
              component={DropdownListInput}
              inputId="tipoEmpresa"
              name="tipoEmpresa"
              label="Tipo de Empresa"
              options={empresasList}
              textField="value"
              valueField="key"
              readOnly={readOnly}
              filter="contains"
              required
            />
          </div>

          <div className="col-lg-2">
            <Field
              component={DropdownListInput}
              inputId="regimeTributario"
              name="regimeTributario"
              label="Regime Tributário"
              options={regimeTributarios}
              textField="value"
              valueField="key"
              readOnly={readOnly}
              filter="contains"
              required
            />
          </div>

          <div className="col-lg-2">
            <Field
              component={MaskedFormatInput}
              inputId="codigoFilial"
              maxLength={6}
              name="codigoFilial"
              type="text"
              label="Código Filial"
              readOnly={!statusValue || statusValue == 2 || view || (statusValue == 1 && !isTreasury)}
              required
            />
          </div>

          <div className="col-lg-2">
            <Field
              component={Input}
              inputId="descricaoStatus"
              name="descricaoStatus"
              type="text"
              label="Status"
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );

  let patrimonioSeriesVinculadas = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Séries Vinculadas</span>
        <div className="d-flex flex-column w-100">
          <div className="col more-fields p-0">
            {series.map((item, index) => {
              return (
                <div key={index} className="more-fields-item">
                  <div className="row">
                      <div className="col-lg">
                        <Field
                          component={MaskedInput}
                          inputId="emissao"
                          name={`series[${index}].emissao`}
                          type="text"
                          label="Número da Emissão"
                          readOnly={readOnly && !isAdmin}
                          outerClassName={readOnly && !isAdmin ? "disabled-field" : ""}
                          required
                        />
                      </div>

                      <div className="col-lg">
                        <Field
                          component={MaskedInput}
                          inputId="serie"
                          name={`series[${index}].serie`}
                          type="text"
                          label="Número da Série"
                          readOnly={readOnly && !isAdmin}
                          outerClassName={readOnly && !isAdmin ? "disabled-field" : ""}
                          required
                        />
                      </div>

                      {((readOnly && isAdmin) || !readOnly) && (
                        <div className="col-12 col-lg-auto d-flex align-items-center mr-3 mt-2">
                          <Button
                            classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                            activeClassName={"col-lg-auto p-0"}
                            buttonStyle={"success"}
                            action={() => removeContaArrecadadora(index)}
                            icon={"trash"}
                            name={"Remover"}
                          />
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>

          {((readOnly && isAdmin) || !readOnly) && (
            <div className="mx-auto mt-3">
              <Button
                classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                activeClassName={"col-lg-auto p-0"}
                buttonStyle={"success"}
                action={() => addContaArrecadadora(series.length)}
                icon={"plus"}
                name={"Adicionar"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  let patrimonioControle = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Controle</span>
        <div className="row">
          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="idProduto"
              name="idProduto"
              label="Produto"
              options={produtosPassivos}
              textField="value"
              valueField="key"
              readOnly={view}
              filter="contains"
              required
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="idUsuarioGestao"
              name="idUsuarioGestao"
              label="Gestão"
              options={usuariosGestaoList}
              textField="nome"
              valueField="id"
              readOnly={view}
              filter="contains"
              required
            />
          </div>

          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="idUsuarioServicoCredito"
              name="idUsuarioServicoCredito"
              label="Serviço de Crédito"
              options={usuariosServicoCreditoList}
              textField="nome"
              valueField="id"
              readOnly={view}
              filter="contains"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Field
              component={Input}
              inputId="dataEncerramentoSocialDF"
              name="dataEncerramentoSocialDF"
              type="date"
              label="Data de Encerramento Exercício Social da 1ª DF"
              readOnly={readOnly && !(isAdmin || isGestor)}
              required
            />
          </div>

          <div className="col-lg-4">
            <Field
              maxLength="5"
              label="Data de Encerramento Exercício Social Anual"
              name="dataEncerramentoSocialAnual"
              inputId="dataEncerramentoSocialAnual"
              formatMask={formatDiaMes}
              className={"filter-input"}
              component={MaskedFormatInput}
              readOnly={readOnly && !(isAdmin || isGestor)}
              placeholder="dd/mm"
            />
          </div>
        </div>
      </div>
    </div>
  );

  let patrimonioCaracteristicas = (
    <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
      <div className="column">
        <span className="page-head__title h3 mb-3">Características</span>
        <div className="row">
          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="idTitulo"
              name="idTitulo"
              label="Título"
              options={titulos}
              textField="descricao"
              valueField="id"
              readOnly={readOnly}
              filter="contains"
              required
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={DropdownListInput}
              inputId="idNaturezaOperacao"
              name="idNaturezaOperacao"
              label="Natureza"
              options={naturezas}
              textField="descricao"
              valueField="id"
              readOnly={view}
              filter="contains"
              required
            />
          </div>
          <div className="col-lg-4">
            <Field
              component={Input}
              inputId="segmento"
              name="segmento"
              type="text"
              label="Segmento"
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column mt-4" id="ptr-geral">
      {patrimonioHeader}
      {patrimonioSeriesVinculadas}
      {patrimonioControle}
      {patrimonioCaracteristicas}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    changeMoeda,
    arrayInsert,
    arrayRemove,
  },
    dispatch
  )
)

const selector = formValueSelector("patrimonioForm");
  
const mapStateToProps = (state) => ({
  patrimonioSeparado: state.patrimonioReducer.patrimonioFormTypes.patrimonioSeparado,
  emissores: state.patrimonioReducer.patrimonioFormTypes.emissores,
  naturezas: state.patrimonioReducer.patrimonioFormTypes.naturezas,
  titulos: state.patrimonioReducer.patrimonioFormTypes.titulos,
  produtosPassivos: state.patrimonioReducer.patrimonioFormTypes.produtosPassivos,
  usuariosGestaoList: state.patrimonioReducer.patrimonioFormTypes.usuariosGestao,
  usuariosServicoCreditoList: state.patrimonioReducer.patrimonioFormTypes.usuariosServicoCredito,
  tipoEmpresas: state.patrimonioReducer.patrimonioFormTypes.tipoEmpresas,
  regimeTributarios: state.patrimonioReducer.patrimonioFormTypes.regimeTributarios,
  series: selector(state, "series") || [],
  statusOperacaoPassivos: state.patrimonioReducer.patrimonioFormTypes.statusOperacaoPassivos,
  statusOperacoes: state.patrimonioReducer.patrimonioFormTypes.statusOperacoes,
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonioFormGeral);