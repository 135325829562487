import axios from "axios";
import { initialize, change, formValueSelector } from "redux-form";

import history from '../../components/common/routers/history';
import {
    deserializeFieldsPedidosForm,
    deserializeFieldsPedidosFormFilter, serializeContasOrigem, serializeFieldsPedidosFluxo, serializeListCNPJDespesa,
} from "../../components/business/pedidos/mappings/PedidosMap";

import { showAllMessages } from '../message/ErrorMessagesAPIAction';

import {
    LISTA_ROUTE_PATH,
    PEDIDOS_ROUTE_PATH,
} from "../../components/common/routers/constantesRoutes";

import {
    downloadExcelFile,
    downloadFile,
} from "../../utils/helpers/FileHelper";
import { calcularDiferencaEmDias, calculateDate } from "../../utils/helpers/DateTimeHelper";
import { showConfirmDialog } from "../common/modal/ConfirmDialogAction";
import { addZerosAEsquerda, msgError } from "../../utils/helpers/OthersHelpers";
import { getStringBooleanValue } from "../../utils/helpers/BooleanHelper";

export const LOAD_FILTER_FORM_PEDIDOS = 'LOAD_FILTER_FORM_PEDIDOS';
export const PEDIDOS_CONSULT_CURRENT = 'PEDIDOS_CONSULT_CURRENT';
export const PEDIDOS_FETCHED = 'PEDIDOS_FETCHED';
export const SALVA_FILTROS_PEDIDOS = 'SALVA_FILTROS_PEDIDOS';
// 
export const PEDIDOS_RESET = 'PEDIDOS_RESET';
export const LOAD_TELA_FLUXOS_PEDIDOS = 'LOAD_TELA_FLUXOS_PEDIDOS';
export const INDICADORES_PEDIDO_FETCHED = 'INDICADORES_PEDIDO_FETCHED';
export const SAVE_FORM_PEDIDOS_ERRORS = 'SAVE_FORM_PEDIDOS_ERRORS';
export const CHANGE_LIST_DESPESAS = 'CHANGE_LIST_DESPESAS';
export const CHANGE_LIST_FORNECEDORES = 'CHANGE_LIST_FORNECEDORES';
export const CHANGE_LIST_CONTA_ORIGEM = 'CHANGE_LIST_CONTA_ORIGEM';
export const CHANGE_LIST_CONTA_DESTINO = 'CHANGE_LIST_CONTA_DESTINO';
export const CHANGE_LIST_SERIES = 'CHANGE_LIST_SERIES';

// 

const PEDIDO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PEDIDO_COMPRA_API;

const OPERACAO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_OPERACAO_API;
  
const DESPESA_API = OPERACAO_API + process.env.REACT_APP_PATH_DESPESA_API;

const PATRIMONIO_API =
    process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PATRIMONIO_API;

const PRESTADORA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRESTADORA_API;
const NATUREZA_API = process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_NATUREZA_API;

function updateFilters() {
  return (dispatch, getState) => {
    const pedidosConsultCurrent = getState().pedidosReducer.pedidosConsultCurrent

    if (!pedidosConsultCurrent) {
      dispatch([
        change('PedidosForm', 'dataPagamentoInicio', calculateDate("-", 15) || ''),
        change('PedidosForm', 'dataPagamentoFinal', calculateDate("+", 15) || ''),
      ]);
    } else {
      const { 
        dataPagamentoInicio,
        dataPagamentoFinal,
        identificador,
        valorBrutoInicial,
        valorBrutoFinal
      } = pedidosConsultCurrent

      dispatch([
        change('PedidosForm', 'dataPagamentoInicio', dataPagamentoInicio || ''),
        change('PedidosForm', 'dataPagamentoFinal', dataPagamentoFinal || ''),
        change('PedidosForm', 'identificador', identificador || ''),
        change('PedidosForm', 'valorBrutoInicial', valorBrutoInicial || 0),
        change('PedidosForm', 'valorBrutoFinal', valorBrutoFinal || 0),
      ]);
    }
  }
}

export function loadFilterFormPedidos() {
  return (dispatch) => {
    updateFilters();
    return axios.get(`${PEDIDO_API}filtro`).then((result) =>
      dispatch([
        {
          type: LOAD_FILTER_FORM_PEDIDOS,
          payload: result.content,
        },
        updateFilters(),
      ])
    );
  };
}

export function getPedidos(filters, page = 1) {
    const params = deserializeFieldsPedidosFormFilter(filters, page);
    
    return (dispatch) => {
        return axios
            .post(`${PEDIDO_API}busca`, params)
            .then(result =>
                dispatch([
                    {
                        type: PEDIDOS_FETCHED,
                        payload: result,
                    },
                    {
                        type: PEDIDOS_CONSULT_CURRENT,
                        payload: {...filters, pagingConfig: { pageIndex: page, pageSize: 10 }},
                    },
                ])
            );
    }
}

export function salvaFiltrosPedidos(filters) {
    return (dispatch, getState) => {
        return dispatch([
            {
                type: SALVA_FILTROS_PEDIDOS,
                payload: filters
            },
        ]);
    }
}

export function onDropDocument(arquivos) {
    return (dispatch, getState) => {
        const selector = formValueSelector("PedidosForm");
        const documentos = selector(getState(), "pedidoCompra.documentoList") || [];

        dispatch([
            change("PedidosForm", "pedidoCompra.documentoList", [...documentos, ...arquivos])
        ])
    }
}

export function onDeleteDocument(index) {
    return (dispatch, getState) => {
        const selector = formValueSelector("PedidosForm");
        const documentos = selector(getState(), "pedidoCompra.documentoList") || [];

        const newFiles = [...documentos];
        newFiles.splice(index, 1);

        dispatch([
            change("PedidosForm", "pedidoCompra.documentoList", newFiles)
        ])
    }
}

export function loadPedidosForm(idPedidos) {
    return dispatch => {
        return axios.get(PEDIDO_API + `tela/${idPedidos || ''}`)
            .then(result => {
                const data = result.content;
                const values = serializeFieldsPedidosFluxo(data);

                dispatch([
                    {
                        type: LOAD_TELA_FLUXOS_PEDIDOS,
                        payload: {}
                    },
                ]);

                if (result.messages.infos.length > 0) {
                    dispatch([
                        showAllMessages(result.messages),
                        sairDaPagina(),
                    ]);
                } else {
                    dispatch([
                        showAllMessages(result.messages),
                        initialize("PedidosForm", values),
                        {
                            type: LOAD_TELA_FLUXOS_PEDIDOS,
                            payload: values
                        },
                    ]);
                }

            });
    };
}

export function savePedidos(pedido) {
    const pedidosDeserialized = deserializeFieldsPedidosForm(pedido);
  
    return (dispatch) => {
      dispatch([
        {
          type: SAVE_FORM_PEDIDOS_ERRORS,
          payload: null,
        },
      ]);
      return axios
        .post(PEDIDO_API, { ...pedidosDeserialized })
        .then((result) =>
          dispatch([showAllMessages(result.messages), showPedidosList()])
        )
        .catch((err) => {
          if (err && err.data?.messages && err.data?.messages?.hasError) {
            document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
            dispatch([
              {
                type: SAVE_FORM_PEDIDOS_ERRORS,
                payload: err.data?.messages,
              },
            ]);
          }
        });
    };
  }

export function editPedidos(pedido) {
    const pedidosDeserialized = deserializeFieldsPedidosForm(pedido, true);
  
    return (dispatch, getState) => {
      const selector = formValueSelector("PedidosForm");
      const typeSubmit = selector(getState(), "pedidoCompra.typeSubmit");

      let url = PEDIDO_API;
      let method = 'put';

      if (typeSubmit === 'enviarExportacao') {
        url = `${PEDIDO_API}enviar-para-exportacao`;
        method = 'post';
      }

      if (typeSubmit === 'enviarPagamento') {
        url = `${PEDIDO_API}enviar-para-pagamento`;
        method = 'post';
      }
      
      dispatch([
        {
          type: SAVE_FORM_PEDIDOS_ERRORS,
          payload: null,
        },
      ]);

      return axios
        .request({ method, url, data: { ...pedidosDeserialized }})
        .then((result) => {
            dispatch([
              showAllMessages(result.messages),
              showPedidosList(),
            ])
        })
        .catch((err) => {
          if (err && err.data?.messages && err.data?.messages?.hasError) {
            document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" });
            dispatch([
              {
                type: SAVE_FORM_PEDIDOS_ERRORS,
                payload: err.data?.messages,
              },
            ]);
          }
        });
    };
  }

export function loadContratoDespesa(append, showMsg = true) {
    return (dispatch) => {
        dispatch([
            {
              type: CHANGE_LIST_DESPESAS,
              payload: [],
            },
        ])

        let url = `${DESPESA_API}listar`
        url = url + append;

        return axios
            .get(url)
            .then(result => {
                const values = serializeListCNPJDespesa(result.content);

                if (!values?.length && showMsg) msgError("Não foi encontrada nenhuma lista para Contrato de Despesa", false);

                dispatch([
                    {
                      type: CHANGE_LIST_DESPESAS,
                      payload: values,
                    },
                ])
            });
    }
}

export function loadSeries(idPS) {
  return (dispatch) => {
      dispatch([
          {
            type: CHANGE_LIST_SERIES,
            payload: [],
          },
      ])

      return axios
          .get(`${PATRIMONIO_API}${idPS}/operacoes`)
          .then(result => {
              if (!result?.content.length) msgError("Não foi encontrada nenhuma lista para Série", false);
              
              dispatch([
                  {
                    type: CHANGE_LIST_SERIES,
                    payload: result?.content || [],
                  },
              ])
          });
  }
}

export function loadContaOrigem(idPS, idOperacao = null, idProduto = null) {
    return (dispatch) => {
        dispatch([
            {
              type: CHANGE_LIST_CONTA_ORIGEM,
              payload: [],
            },
        ])

        let url = `${PATRIMONIO_API}${idPS}/contas-arrecadadoras`;
        if (idOperacao) url = url + `?idOperacao=${idOperacao}`
        if (idProduto) url += `${idOperacao ? '&' : '?'}idProduto=${idProduto}`;

        return axios
            .get(url)
            .then(result => {
                const values = serializeContasOrigem(result.content);

                if (!values?.length) msgError("Não foi encontrada nenhuma lista para Conta Origem", false);

                dispatch([
                    {
                      type: CHANGE_LIST_CONTA_ORIGEM,
                      payload: values,
                    },
                ])
            });
    }
}

export function loadContaDestino(idFornecedor) {
    return (dispatch) => {
      return axios
            .get(`${PRESTADORA_API}contas/${idFornecedor}`)
            .then(result => {
                dispatch([
                  {
                    type: CHANGE_LIST_CONTA_DESTINO,
                    payload: [],
                  },
                ])

                if (!result.content?.length) msgError("Não foi encontrada nenhuma lista para Conta Origem", false);

                dispatch([
                    {
                      type: CHANGE_LIST_CONTA_DESTINO,
                      payload: result.content,
                    },
                ])
            });
    }
}

export async function actionPedido(id, type) {
    return (dispatch) => {
        return axios
            .post(`${PEDIDO_API}${type}/${id}`, { id })
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    type !== "revisar" ? showPedidosList() : document.getElementById("topContent").scrollTo({ top: 0, behavior: "smooth" }),
                    type == "revisar" ? loadPedidosForm(id) : null,
                ])
            );
    }
}

export function downloadPedidoDocument(params) {
    return (dispatch) => {
        return axios
            .post(`${PEDIDO_API}exportar-documento`, params)
            .then(result => {
                if (result?.content){
                  const { content } = result;

                  dispatch([
                    downloadFile(content?.arquivoAnexado, `${content?.nomeArquivo}.${content?.formatoArquivo}`),
                    showAllMessages(result.messages),
                  ])
                } else {
                  msgError("Arquivo não encontrado", false);
                  return false;
                }
            });
    }
}

export function showPedidosList() {
  return (dispatch) => {
    return dispatch([
      showConfirmDialog(false),
      sairDaPagina(),
    ]);
  };
}

export function sairDaPagina() {
    return dispatch => {
        return dispatch([
            { type: PEDIDOS_RESET },
            history.push(`/${PEDIDOS_ROUTE_PATH}/${LISTA_ROUTE_PATH}`)
        ]);
    };
}

export function limpaDependentesOnChange(dependentes) {
    return (dispatch) => {
      const actions = dependentes.map(dependente => change("PedidosForm", dependente, null));
      dispatch(actions);
    };
  }
  
  export function mudaDependenteOnChange(dependente, value = null) {
    return (dispatch) => {
      dispatch([change("PedidosForm", dependente, value)]);
    };
  }

  export async function alteraCondicaoProtheus(data1, data2) {
    const value = await calcularDiferencaEmDias(data1, data2);

    return (dispatch) => {
      dispatch([
        mudaDependenteOnChange("pedidoCompra.escrituracao.condicaoProtheus", value)
      ]);
    };
  }

  export async function alteraNumeroDocumento(number, qnt) {
    const value = await addZerosAEsquerda(number, qnt);
    
    return (dispatch) => {
      dispatch([
        mudaDependenteOnChange("pedidoCompra.numeroDocumento", value)
      ]);
    };
  }

// 

export function getIndicadores(filters) {
    let idsFornecedores = filters && filters.idsFornecedores ? filters.idsFornecedores.map(o => o.id) : null;
    let idsOperacoes = filters && filters.idsOperacoes ? filters.idsOperacoes.map(o => o.key) : null;
    let idsPatrimonioSeparados = filters && filters.idsPatrimonioSeparados ? filters.idsPatrimonioSeparados.map(o => o.key) : null;
    let idsSolicitantes = filters && filters.idsSolicitantes ? filters.idsSolicitantes.map(o => o.id) : null;
    let idsTipoDespesas = filters && filters.idsTipoDespesas ? filters.idsTipoDespesas : null;
    let idsTipoItens = filters && filters.idsTipoItens ? filters.idsTipoItens.map(o => o.key) : null;
    let idsTipoPagamentos = filters && filters.idsTipoPagamentos ? filters.idsTipoPagamentos.map(o => o.key) : null;

    const data = {
      idsFornecedores,
      idsOperacoes,
      idsPatrimonioSeparados,
      idsSolicitantes,
      idsTipoDespesas,
      idsTipoItens,
      idsTipoPagamentos,
    }

    return (dispatch) => {
        return axios
            .post(`${PEDIDO_API}indicadores`, {...filters, ...data})
            .then(result =>
                dispatch([
                    {
                        type: INDICADORES_PEDIDO_FETCHED,
                        payload: result,
                    },
                ])
            );
    }
}

export function exportPedidosToExcel(filters) {
  const params = deserializeFieldsPedidosFormFilter(filters, 1);

  return (dispatch) => {
      return axios
          .post(`${PEDIDO_API}exportar`, params)
          .then(result => {
              dispatch([
                  showAllMessages(result.messages),
              ])

              if (result.content) {
                const { content } = result;

                dispatch([
                  content ? downloadExcelFile(content?.arquivo, `${content?.nomeArquivo}.${content?.extensaoArquivo}`) : null,
                ])
              }
          }); 

  }
}

export function getDespesa(idDespesa, origemRecursoList) {
  return (dispatch, getState) => {
    const fornecedorList = getState().pedidosReducer.pedidosFormTypes.fornecedorList

    return axios
      .get(DESPESA_API + idDespesa)
      .then((result) => {
        const despesaRec = result?.content?.despesaPagamentoRecorrente;
        const origem = origemRecursoList?.find((item) => item.id === despesaRec?.idOrigemRecurso) || null;
        const idsFornecedores = result?.content?.idsFornecedores
        const newListFornecedores = fornecedorList.filter(obj => idsFornecedores.includes(obj.id));

        dispatch([
          change("PedidosForm", "pedidoCompra.idContaOrigem", despesaRec?.idContaOrigem),
          change("PedidosForm", "pedidoCompra.idOrigemRecurso", despesaRec?.idOrigemRecurso),
          change("PedidosForm", "pedidoCompra.escrituracao.carimbo", origem?.carimbo),
          change("PedidosForm", "pedidoCompra.reembolsavel", getStringBooleanValue(despesaRec?.despesaReembolsavel)),
          change("PedidosForm", "pedidoCompra.idReembolsante", despesaRec?.idReembolsante),
        ])

        if (!newListFornecedores?.length) {
          msgError("Não foi encontrada nenhuma lista para Fornecedores", false);
        } else {
          dispatch([
              {
                type: CHANGE_LIST_FORNECEDORES,
                payload: newListFornecedores,
              },
          ])
        }
      });
  };
}

export function loadValoresProtheus(idNatureza, valorBruto) {
  return (dispatch) => {
    return axios
      .get(`${NATUREZA_API}${idNatureza}/calcular-imposto?valor=${valorBruto}`)
      .then((result) => {
        const valores = result?.content;

        dispatch([
          change("PedidosForm", "pedidoCompra.valorLiquido", valores?.valorLiquido),
          change("PedidosForm", "pedidoCompra.valorImposto", valores?.valorImposto),
        ])
      });
  };
}