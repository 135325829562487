/* eslint eqeqeq: 0 */

import { getBooleanValue, getBooleanValueFromString, getStringBooleanValue } from "../../../../utils/helpers/BooleanHelper";
import { getCurrentDateTimeStringNormal, getDateTimeString } from "../../../../utils/helpers/DateTimeHelper";
import { convertCurrencyToNumber } from "../../../../utils/helpers/NumberHelper";
import { extractFromObj } from "../../../../utils/helpers/ObjectHelper";
import { formatCNPJ } from "../../../../utils/helpers/StringHelper";

// envio
export function deserializeFieldsPedidosFormFilter(filters, page) {
  let params = {};

  if (filters) {
    params = {
      ...filters,
      idsOperacoes: filters?.idsOperacoes ? filters?.idsOperacoes.map((o) => o.key) : null,
      idsPatrimonioSeparados: filters?.idsPatrimonioSeparados ? filters?.idsPatrimonioSeparados.map((o) => o.key) : null,

      idsSolicitantes: filters?.idsSolicitantes ? filters?.idsSolicitantes.map((o) => o.id) : null,
      idsTipoItens: filters?.idsTipoItens ? filters?.idsTipoItens.map((o) => o.key) : null,
      idsTipoDespesas: filters?.idsTipoDespesas ? filters?.idsTipoDespesas : null,
      idsTipoPagamentos: filters?.idsTipoPagamentos ? filters?.idsTipoPagamentos.map((o) => o.key) : null,
      idsFornecedores: filters?.idsFornecedores ? filters?.idsFornecedores.map((o) => o.id) : null,
      status: (filters?.status && filters?.status?.length) ? filters?.status : null,

      pagingConfig: {
        pageIndex: page,
        pageSize: 10,
      },
    };
  }

  return params;
}

// recebimento
export function serializeFieldsPedidosFluxo(data) {
  let values = {
    ...data,
    fornecedorList: serializeListCNPJ(data.fornecedorList),
    reembolsanteList: serializeListCNPJ(data.reembolsanteList),
    contaOrigemList: serializeContasOrigem(data.contaOrigemList),
    // contaDestinoList: serializeContasDestino(data.contaDestinoList),
    despesaList: serializeListCNPJDespesa(data.despesaList),
    bancoEmissorList: serializeBancoEmissor(data.bancoEmissorList),
    pedidoCompra: {
      ...data.pedidoCompra,
      despesaSerie: getStringBooleanValue(data?.pedidoCompra?.despesaSerie),
      reembolsavel: getStringBooleanValue(data?.pedidoCompra?.reembolsavel),
      dataPrevisaoPagamento: getCurrentDateTimeStringNormal(data?.pedidoCompra?.dataPrevisaoPagamento),
      dataEmissao: getCurrentDateTimeStringNormal(data?.pedidoCompra?.dataEmissao),
    },
  }

  if (data.pedidoCompra?.aprovacao) {
    values = {
      ...values,
      pedidoCompra: {
        ...values?.pedidoCompra,
        aprovacao: {
          ...data.pedidoCompra?.aprovacao,
          dataSolicitacao: getCurrentDateTimeStringNormal(data?.pedidoCompra?.aprovacao?.dataSolicitacao),
        }
      }
    }
  }

  return values; 
}

// recebimento
export function serializeContasOrigem(contas) {
  let values = []

  if (contas && contas?.length) {
    return contas.map(item => ({
      ...item,
      nomeExibicao: `${item.tipoConta} (${item?.codigoBanco}_${item?.agencia}${item?.agenciaDigito ? `-${item?.agenciaDigito}` : ''}/${item?.conta}${item?.contaDigito ? `-${item.contaDigito}` : ''})`
    }));
  }

  return values; 
}

// recebimento
export function serializeContasDestino(contas) {
  let values = []

  if (contas && contas?.length) {
    return contas.map(item => ({
      ...item,
      nomeExibicao: `${item?.banco?.codigo}_${item?.agencia}${item?.agenciaDigito ? `-${item.agenciaDigito}` : ''}/${item?.conta}${item?.contaDigito ? `-${item.contaDigito}` : ''}`
    }));
  }

  return values; 
}

// recebimento
export function serializeListCNPJ(list) {
  let values = []

  if (list && list?.length) {
    return list.map(item => ({
      ...item,
      nomeExibicao: `${item.nomeSimplificado} (CNPJ: ${formatCNPJ(item.cnpj)})`,
    }));
  }

  return values; 
}

// recebimento
export function serializeListCNPJDespesa(list) {
  let values = []

  if (list && list?.length) {
    return list.map(item => ({
      ...item,
      nomeExibicao: `${item.tipoDespesa} - ${item.fornecedor} (${item.cnpjFornecedor})`,
    }));
  }

  return values; 
}

// recebimento
export function serializeBancoEmissor(list) {
  let values = []

  if (list && list?.length) {
    return list.map(item => ({
      ...item,
      nomeExibicao: `${item.codigoBanco} - ${item.nomeExibicao}`,
    }));
  }

  return values; 
}

// envio
export function deserializeFieldsPedidosForm(pedido, edit = false) {
  const data = pedido?.pedidoCompra;

  const fornecedor = pedido?.fornecedorList?.find((item) => item?.id == data?.idFornecedor);

  let values = {
    ...data,
    fornecedor: !data?.fornecedor && data?.idFornecedor ? fornecedor : data?.fornecedor,
    valorBruto: convertCurrencyToNumber(data?.valorBruto),
    reembolsavel: getBooleanValueFromString(data?.reembolsavel),
    despesaSerie: getBooleanValueFromString(data?.despesaSerie),
    idProduto: extractFromObj(data?.idProduto),
    idPatrimonioSeparado: extractFromObj(data?.idPatrimonioSeparado),
    idOrigemRecurso: extractFromObj(data?.idOrigemRecurso),
    idReembolsante: extractFromObj(data?.idReembolsante),
    idFornecedor: extractFromObj(data?.idFornecedor),
    idContaDestino: extractFromObj(data?.idContaDestino),
    idBancoEmissor: extractFromObj(data?.idBancoEmissor),
    idContaOrigem: extractFromObj(data?.idContaOrigem),
    idDespesa: extractFromObj(data?.idDespesa, "idDespesa"),
    idOperacao: extractFromObj(data?.idOperacao),
    idMovimentacaoFinanceira: extractFromObj(data?.idMovimentacaoFinanceira),
  }

  if (data?.escrituracao) {
    values = {
      ...values,
      escrituracao: {
        ...data.escrituracao,
        idNatureza: data?.escrituracao?.idNatureza ? extractFromObj(data?.escrituracao?.idNatureza) : null,
        idTes: data?.escrituracao?.idTes ? extractFromObj(data?.escrituracao?.idTes, "key") : null,
      }
    }
  }

  return values;
}
