import {
    PRESTADORA_FETCHED,
    PRESTADORA_SHOW_DELETE_DIALOG,
    LOAD_FORM_PRESTADORA,
    CLEAN_LIST_CONTENT,
    VALIDATE_DELETE_DIALOG_MESSAGE,
    SAVE_FORM_PRESTADORA_ERRORS,
    INITIAL_STATE_PRESTADORA,
    LOAD_FILTER_PRESTADORA,
  } from "../../actions/prestadora/PrestadoraAction";

const INITIAL_STATE = {
    prestadoras: {
      lastPage: 0,
      totalCount: 0,
      hasItems: false,
      pageSize: 0,
      pageIndex: 0,
      items: [],
    },
    filters: null,
    showPrestadoraDeleteDialog: {
      showDialog: false,
      idPrestadora: 0,
      message: ""
    },
    prestadoraFormTypes: {
      servicosPrestados:[]
    },
    messages: null, 
    
}

export default function(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
      case INITIAL_STATE_PRESTADORA: {
        return INITIAL_STATE
      }
      case PRESTADORA_FETCHED:
        return {
          ...state,
            prestadoras: action.payload.content, 
            messages: action.payload.messages,
        }
      case LOAD_FILTER_PRESTADORA:
        return {
          ...state,
            filters: action.payload, 
            messages: action.payload.messages,
        }
      case LOAD_FORM_PRESTADORA:
        return {
          ...state,
          prestadoraFormTypes: action.payload
        }
      case SAVE_FORM_PRESTADORA_ERRORS:
        return {
          ...state,
          messages: action.payload
        }
      case PRESTADORA_SHOW_DELETE_DIALOG:
          return {
              ...state,
              showPrestadoraDeleteDialog: action.payload
          }
      case CLEAN_LIST_CONTENT:
          return {
                ...state,
                prestadoras: {
                  lastPage: 0,
                  totalCount: 0,
                  hasItems: false,
                  pageSize: 0,
                  pageIndex: 0,
                  items: [],
                }
            }
        case VALIDATE_DELETE_DIALOG_MESSAGE:
            return {
                ...state,
                showPrestadoraDeleteDialog: { ...state.showPrestadoraDeleteDialog, message: action.payload }
            }
        default: 
          return state; 
    }
}