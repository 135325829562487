/* eslint react-hooks/exhaustive-deps: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { withRouter } from "react-router-dom";
import Button from "../../../../common/button/Button";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import {
  actionModal,
  loadContratoDespesa,
} from "../../../../../actions/aprovacao/AprovacaoAction";
import history from "../../../../common/routers/history";
import {
  APROVACAO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";
import { APROVACAO, READ } from "../../../../../utils/actionsAndFeaturesAuth";

let ModalContratoDespesa = (props) => {
  const {
    actionModal,
    open,
    contrato,
    permiteAprovar,
    idAprovacao,
    loadContratoDespesa,
    authorizationFunction,
  } = props;

  const [observacao, setObservacao] = React.useState(
    contrato?.observacaoDespesa || ""
  );

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: APROVACAO },
        () => loadContratoDespesa(idAprovacao)
      );
    }
  
    if (open) fetchLoad();
  }, [open]);  

  if (!open) return null;

  const close = () => {
    history.push(`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`);
  };

  const moedaSimbolo = "R$";
  const identificador = contrato?.identificador || null;

  return Object.keys(contrato)?.length > 0 ? (
    <Dialog maxWidth="xl" open={open} className="modalDialog" onClose={close}>
      <div className="modal-dialog modal-xl modal-fullscreen-2 modal-dialog-scrollable m-0 overflow-hidden">
        <div className="modal-content">
          <div className="modal-header p-4 d-flex flex-row align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <span className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                {contrato?.patrimonioSeparado}
              </span>
              <span className="f-22 m-0 mt-2 txt-blue-link-dark">
                Contrato de Despesa
                {`${
                  contrato?.fornecedorNomeSimplificado
                    ? ` - ${contrato?.fornecedorNomeSimplificado}`
                    : null
                }`}
              </span>
            </div>
            <Close role="button" className="text-danger" onClick={close} />
          </div>

          <div className="modal-body px-4 py-0">
            <div className="text-dark f-16">
              {/*  */}
              <div className="p-color f-20 fw-500 my-2">Fornecedor</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Fornecedor</p>
                  <p className="fw-400 mb-0">{contrato?.fornecedor || "--"}</p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">CNPJs de Faturamento</p>
                  <p className="fw-400 mb-0 d-flex flex-column">
                    {contrato?.faturamentos?.map((fat, i) => {
                      return (
                        <p key={i} className="mb-0">
                          {fat}
                        </p>
                      );
                    })}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Contrato</div>
              <div className="row w-100 mb-3">
                <div className="col-lg-3">
                  <p className="mb-1">Controle da Despesa</p>
                  <p className="fw-400 mb-0">
                    {contrato?.controleDespesa || "--"}
                  </p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Série</p>
                  <p className="fw-400 mb-0">{contrato?.serie || "--"}</p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Tipo de Despesa</p>
                  <p className="fw-400 mb-0">{contrato?.tipoDespesa || "--"}</p>
                </div>
                <div className="col-lg-3">
                  <p className="mb-1">Documento Suporte</p>
                  <p className="fw-400 mb-0">
                    {contrato?.documentoSuporte || "--"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">
                Pagamento Flat
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoFlat?.contaOrigem || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem dos Recursos</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoFlat?.origemRecurso || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Valor Bruto</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoFlat?.valorBruto
                      ? convertCurrencyNumber(
                          contrato?.despesaPagamentoFlat?.valorBruto,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data de Vencimento</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoFlat?.dataVencimento
                      ? getDateFormatedFromString(
                          contrato?.despesaPagamentoFlat?.dataVencimento
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Reembolsante</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoFlat?.reembolsante || "--"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">
                Pagamento Recorrente
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoRecorrente?.contaOrigem || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem dos Recursos</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoRecorrente?.origemRecurso ||
                      "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Valor Bruto Inicial</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoRecorrente?.valorBrutoInicial
                      ? convertCurrencyNumber(
                          contrato?.despesaPagamentoRecorrente
                            ?.valorBrutoInicial,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Periodicidade</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoRecorrente?.periodicidade ||
                      "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Reembolsante</p>
                  <p className="fw-400 mb-0">
                    {contrato?.despesaPagamentoRecorrente?.reembolsante || "--"}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Aprovação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Enviado por</p>
                  <p className="fw-400 mb-0">{contrato?.enviadoPor || "--"}</p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora de Envio</p>
                  <p className="fw-400 mb-0">
                    {contrato?.dataHoraEnvio
                      ? getDateFormatedFromString(contrato?.dataHoraEnvio)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">ID Aprovação</p>
                  <p className="fw-400 mb-0">{contrato?.idFormatado || "--"}</p>
                </div>
                <div className="col-lg"></div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 1</p>
                  <p className="fw-400 mb-0">{contrato?.aprovador1 || "--"}</p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.dataAprovacao1
                      ? getDateFormatedFromString(contrato?.dataAprovacao1)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Status</p>
                  <p className="fw-400 mb-0">
                    {contrato?.statusAprovacao1?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">{contrato?.observacao1 || "--"}</p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 2</p>
                  <p className="fw-400 mb-0">{contrato?.aprovador2 || "--"}</p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {contrato?.dataAprovacao2
                      ? getDateFormatedFromString(contrato?.dataAprovacao2)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Status</p>
                  <p className="fw-400 mb-0">
                    {contrato?.statusAprovacao2?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">{contrato?.observacao2 || "--"}</p>
                </div>
              </div>

              {permiteAprovar && (
                <>
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Observação
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 pr-0">
                      <textarea
                        className="w-100 rounded brd-1 p-2"
                        rows={7}
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {permiteAprovar && (
            <div className="modal-footer px-3 py-2 d-flex flex-row align-content-center justify-content-between m-0">
              <Button
                name={"Rejeitar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "reprovar",
                    identificador: identificador,
                    observacao,
                  });
                  close();
                }}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <Button
                name={"Aprovar Contrato"}
                action={async () => {
                  await actionModal({
                    action: "aprovar",
                    identificador: identificador,
                    observacao,
                  });
                  close();
                }}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  contrato: state.aprovacaoReducer.contratoDespesaAprovacao,
  permiteAprovar:
    state.aprovacaoReducer.contratoDespesaAprovacao?.permiteAprovar,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionModal,
      loadContratoDespesa,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalContratoDespesa)
);
