/* eslint eqeqeq: 0 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, arrayInsert, arrayRemove } from "redux-form";
import { bindActionCreators } from "redux";

import {
  CNPJ,
  customFormatCnpjCpf,
} from "../../../../../../utils/helpers/StringHelper";

import If from "../../../../../common/If/If";
import Input from "../../../../../common/input/Input";
import MaskedInput from "../../../../../common/input/MaskedInput";
import MaskedFormatInput from "../../../../../common/input/MaskedFormatInput";
import DropdownListInput from "../../../../../common/input/DropdownListInput";
import Textarea from "../../../../../common/input/Textarea";

import { changeMoeda, mudaDependenteOnChange } from "../../../../../../../src/actions/ativo/AtivoAction";
import Button from "../../../../../common/button/Button";

class AtivoFormGeral extends Component {
  componentDidMount() {
    if (this.props.originadorAtivoList.length === 0) {
      this.addOriginador(this.props.originadorAtivoList.length);
    }

    if (this.props.devedorAtivoList.length === 0) {
      this.addDevedorAtivo(this.props.devedorAtivoList.length);
    }

    if (this.props.codigoB3CetipList.length === 0) {
      this.addCodigoB3Cetip(this.props.codigoB3CetipList.length);
    }
  }

  addOriginador(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("ativoForm", "originadorAtivoList", index, {});
    }
  }

  removeOriginador(index) {
    if (!this.props.readOnly && this.props.originadorAtivoList.length > 0) {
      this.props.arrayRemove("ativoForm", "originadorAtivoList", index);
    }
  }

  addDevedorAtivo(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("ativoForm", "devedorAtivoList", index, {});
    }
  }

  removeDevedorAtivo(index) {
    if (!this.props.readOnly && this.props.devedorAtivoList.length > 0) {
      this.props.arrayRemove("ativoForm", "devedorAtivoList", index);
    }
  }

  addCodigoB3Cetip(index) {
    if (!this.props.readOnly) {
      this.props.arrayInsert("ativoForm", "codigoB3CetipList", index, {});
    }
  }

  removeCodigoB3Cetip(index) {
    if (!this.props.readOnly && this.props.codigoB3CetipList.length > 0) {
      this.props.arrayRemove("ativoForm", "codigoB3CetipList", index);
    }
  }

  allowedPercentualCedido(value) {
    const { formattedValue, floatValue } = value;
    return formattedValue === "" || floatValue <= 100;
  }

  render() {
    const {
      readOnly,
      devedorAtivoList,
      originadorAtivoList,
      contratoAtivoList,
      segmentoAtivoList,
      auditoriaList,
      servicerList,
      custodianteList,
      performanceList,
      tipoDevedorList,
      moedasList,
      changeMoeda,
      mudaDependenteOnChange,
      pessoaDevedorList,
      pessoaCedenteList,
      pessoaOriginadorList,
    } = this.props;

    let ativoHeader = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Ativo</span>
          <div className="row">
            <div className="col-xl-2 col-lg-3">
              <Field
                component={Input}
                inputId="codigoAtivo"
                name="codigoAtivo"
                type="text"
                label="Código do Ativo"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-4 col-lg-9">
              <Field
                component={DropdownListInput}
                inputId="contratoAtivo"
                name="contratoAtivo"
                label="Contrato"
                options={contratoAtivoList}
                textField="descricao"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            <div className="col-xl-2 col-lg-3">
              <Field
                component={Input}
                inputId="dataAssinaturaContrato"
                name="dataAssinaturaContrato"
                type="date"
                label="Data Assinatura Contrato"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-2 col-lg-3">
              <Field
                component={Input}
                inputId="dataVencimentoContrato"
                name="dataVencimentoContrato"
                type="date"
                label="Data de Vencimento"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-2 col-lg-6">
              <Field
                component={DropdownListInput}
                inputId="performance"
                name="performance"
                label="Performance"
                options={performanceList}
                textField="value"
                valueField="key"
                filter="contains"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="segmentoAtivoDescricao"
                name="segmentoAtivo"
                label="Segmento"
                options={segmentoAtivoList}
                textField="descricao"
                valueField="id"
                readOnly={readOnly}
                filter="contains"
              />
            </div>

            <div className="col-xl-4">
              <Field
                component={DropdownListInput}
                inputId="empresaServicerRazaoSocial"
                name="empresaServicer"
                label="Servicer"
                options={servicerList}
                textField="razaoSocial"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-4">
              <Field
                component={DropdownListInput}
                inputId="empresaCustodianteRazaoSocial"
                name="empresaCustodiante"
                label="Custodiante"
                options={custodianteList}
                textField="razaoSocial"
                valueField="idEmpresaPrestadora"
                filter="contains"
                readOnly={readOnly}
              />
            </div>

            <div className="col-xl-2">
              <Field
                component={DropdownListInput}
                inputId="moeda"
                name="moeda"
                label="Moeda"
                options={moedasList}
                textField="descricao"
                valueField="descricao"
                filter="contains"
                readOnly={readOnly}
                onChange={(v) => changeMoeda(v?.simbolo)}
              />
            </div>

            <div className="col-xl-12 col-lg-12">
              <Field
                component={Textarea}
                inputId="descricaoAtivo"
                name="descricao"
                type="text"
                rows={1000}
                maxLength={1000}
                label="Descrição"
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );

    let ativoDevedor = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Devedor</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              {devedorAtivoList &&
                devedorAtivoList.map((item, index) => {
                  return (
                    <div key={index} className="more-fields-item">
                      <div className="d-flex">
                        <div className="row w-100">
                          <div className="col-12 col-lg-2">
                            <Field
                              component={DropdownListInput}
                              inputId="tipoDevedor"
                              name={`devedorAtivoList[${index}].tipoDevedor`}
                              label="Tipo"
                              options={tipoDevedorList}
                              textField="value"
                              valueField="key"
                              readOnly={readOnly}
                              filter="contains"
                            />
                          </div>

                          <div className={`col-12 col-lg-${item.devedorAuditado == "Sim" ? '3' : '5'}`}>
                            <Field
                              component={DropdownListInput}
                              inputId="devedor"
                              name={`devedorAtivoList[${index}].descricao`}
                              label="Devedor"
                              options={pessoaDevedorList}
                              textField="razaoSocial"
                              readOnly={readOnly}
                              filter="contains"
                              onChange={(_e, v) => mudaDependenteOnChange(`devedorAtivoList[${index}].cnpj`, v ? v?.cnpj : null)}
                            />
                          </div>

                          <div className="col-12 col-lg-2">
                            <Field
                              maxLength="18"
                              label="CNPJ/CPF"
                              readOnly={true}
                              inputId="devedorCNPJ"
                              component={MaskedFormatInput}
                              formatMask={customFormatCnpjCpf}
                              name={`devedorAtivoList[${index}].cnpj`}
                            />
                          </div>

                          <div className="col-12 col-lg-2">
                            <Field
                              component={DropdownListInput}
                              inputId="devedorAuditado"
                              name={`devedorAtivoList[${index}].devedorAuditado`}
                              label="Devedor auditado ?"
                              readOnly={readOnly}
                            />
                          </div>

                          <If
                            test={
                              item.devedorAuditado &&
                              item.devedorAuditado === "Sim"
                            }
                          >
                            <div className="col-12 col-lg-3">
                              <Field
                                component={DropdownListInput}
                                inputId="empresaAuditoria"
                                name={`devedorAtivoList[${index}].empresaAuditoria`}
                                label="Auditoria"
                                options={auditoriaList}
                                textField="razaoSocial"
                                valueField="idEmpresaPrestadora"
                                filter="contains"
                                readOnly={readOnly}
                              />
                            </div>
                          </If>

                          <If test={!readOnly}>
                            <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                              <Button
                                classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                                activeClassName={"col-lg-auto p-0"}
                                buttonStyle={"success"}
                                action={() => this.removeDevedorAtivo(index)}
                                icon={"trash"}
                                name={"Remover"}
                              />
                            </div>
                          </If>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <If test={!readOnly}>
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() => this.addDevedorAtivo(devedorAtivoList.length)}
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            </If>
          </div>
        </div>
      </div>
    );

    let ativoCedente = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Cedente</span>
          <div className="row">
            <div className="col-12 col-lg-3">
              <Field
                name="cnpj"
                label="CNPJ"
                maxLength="18"
                readOnly={true}
                inputId="cedenteCnpj"
                component={MaskedFormatInput}
                placeholder={CNPJ.placeholder}
                formatMask={customFormatCnpjCpf}
              />
            </div>

            <div className="col-12 col-lg-6">
              <Field
                component={DropdownListInput}
                inputId="cedente"
                name="razaoSocial"
                label="Cedente"
                options={pessoaCedenteList}
                textField="razaoSocial"
                readOnly={readOnly}
                filter="contains"
                onChange={(_e, v) => mudaDependenteOnChange("cnpj", v ? v?.cnpj : null)}
              />
            </div>

            <div className="col-12 col-lg-3">
              <Field
                component={MaskedInput}
                inputId="percentualCedido"
                name="percentualCedido"
                label="% Cedido"
                readOnly={readOnly}
                suffix={"%"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={4}
                fixedDecimalScale
                isAllowed={(value) => this.allowedPercentualCedido(value)}
              />
            </div>
          </div>
        </div>
      </div>
    );

    let ativoOriginador = (
      <div className="shadow-custom bg-white rounded-12 col-12 mb-3 p-4">
        <div className="column">
          <span className="page-head__title h3 mb-3">Originador</span>
          <div className="d-flex flex-column w-100">
            <div className="col more-fields p-0">
              {originadorAtivoList.map((item, index) => {
                return (
                  <div key={index} className="more-fields-item">
                    <div className="d-flex">
                      <div className="row w-100">
                        <div className="col-12 col-lg-4">
                          <Field
                            label="CNPJ"
                            maxLength="18"
                            readOnly={true}
                            inputId="originadorCnpj"
                            component={MaskedFormatInput}
                            placeholder={CNPJ.placeholder}
                            formatMask={customFormatCnpjCpf}
                            name={`originadorAtivoList[${index}].originador.cnpj`}
                          />
                        </div>

                        <div className="col-12 col-lg-7">
                          <Field
                            component={DropdownListInput}
                            inputId="originadorRazaoSocial"
                            name={`originadorAtivoList[${index}].originador.razaoSocial`}
                            label="Originador"
                            options={pessoaOriginadorList}
                            textField="razaoSocial"
                            readOnly={readOnly}
                            filter="contains"
                            onChange={(_e, v) => mudaDependenteOnChange(`originadorAtivoList[${index}].originador.cnpj`, v ? v?.cnpj : null)}
                          />
                        </div>

                        <If test={!readOnly}>
                          <div className="col-12 col-lg-1 d-flex align-items-center minus-box-btn">
                            <Button
                              classes="rounded bg-white shadow-none border-0 text-danger m-0 mxh-40 p-0 my-2 text-center text-capitalize text-nowrap"
                              activeClassName={"col-lg-auto p-0"}
                              buttonStyle={"success"}
                              action={() => this.removeOriginador(index)}
                              icon={"trash"}
                              name={"Remover"}
                            />
                          </div>
                        </If>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <If test={!readOnly}>
              <div className="mx-auto mt-3">
                <Button
                  classes="rounded bg-white p-color shadow-none brd-p-color-imp m-0 mxh-40 px-3 py-2 text-capitalize"
                  activeClassName={"col-lg-auto p-0"}
                  buttonStyle={"success"}
                  action={() => this.addOriginador(originadorAtivoList.length)}
                  icon={"plus"}
                  name={"Adicionar"}
                />
              </div>
            </If>
          </div>
        </div>
      </div>
    );

    return (
      <div className="d-flex flex-column mt-4">
        {ativoHeader}
        {ativoDevedor}
        {ativoCedente}
        {ativoOriginador}
      </div>
    );
  }
}

const selector = formValueSelector("ativoForm");

const mapStateToProps = (state) => ({
  pagamento: selector(state, "pagamento"),
  codigoB3CetipList: selector(state, "codigoB3CetipList") || [],
  originadorAtivoList: selector(state, "originadorAtivoList") || [],
  devedorAtivoList: selector(state, "devedorAtivoList") || [],
  contratoAtivoList: state.ativoReducer.ativoFormTypes.contratoAtivoList,
  segmentoAtivoList: state.ativoReducer.ativoFormTypes.segmentoAtivoList,
  auditoriaList: state.ativoReducer.ativoFormTypes.auditoriaList,
  servicerList: state.ativoReducer.ativoFormTypes.servicerList,
  custodianteList: state.ativoReducer.ativoFormTypes.custodianteList,
  performanceList: state.ativoReducer.ativoFormTypes.performanceList,
  tipoDevedorList: state.ativoReducer.ativoFormTypes.tipoDevedorList,
  moedasList: state.ativoReducer.ativoFormTypes.moedas,
  pessoaDevedorList: state.ativoReducer.ativoFormTypes.pessoaDevedorList,
  pessoaCedenteList: state.ativoReducer.ativoFormTypes.pessoaCedenteList,
  pessoaOriginadorList: state.ativoReducer.ativoFormTypes.pessoaOriginadorList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    arrayInsert,
    arrayRemove,
    changeMoeda,
    mudaDependenteOnChange
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AtivoFormGeral);
