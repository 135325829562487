/* eslint eqeqeq: 0 */

import axios from "axios";
import { formValueSelector, arrayPush, arrayRemove, change, initialize } from 'redux-form';

import { EMPRESA_PRESTADORA_ROUTE_PATH, NOVO_ROUTE_PATH, DETALHAR_ROUTE_PATH, EDITAR_ROUTE_PATH } from "./../../components/common/routers/constantesRoutes";
import history from './../../components/common/routers/history';

import { showConfirmDialog } from './../common/modal/ConfirmDialogAction';
import { showAllMessages } from "./../../actions/message/ErrorMessagesAPIAction";

import {
    deserializeFieldsPrestadoraForm,
    sereializeFieldsPrestadoraFilter,
    serializeFieldsPrestadoraForm,
    sereializeFieldsCnpjValidation
} from "./../../components/business/prestadora/mappings/PrestadoraMap";
import { toastr } from "react-redux-toastr";
import { formatStrToCnpjOrCpf } from "../../utils/helpers/StringHelper";
import { downloadExcelFile } from "../../utils/helpers/FileHelper";

export const PRESTADORA_FETCHED = 'PRESTADORA_FETCHED';
export const LOAD_FORM_PRESTADORA = "LOAD_FORM_PRESTADORA";
export const PRESTADORA_SHOW_DELETE_DIALOG = 'PRESTADORA_SHOW_DELETE_DIALOG';
export const CLEAN_LIST_CONTENT = "CLEAN_LIST_CONTENT";
export const VALIDATE_DELETE_DIALOG_MESSAGE = "VALIDATE_DELETE_DIALOG_MESSAGE";
export const SAVE_FORM_PRESTADORA_ERRORS = "SAVE_FORM_PRESTADORA_ERRORS";
export const INITIAL_STATE_PRESTADORA = "INITIAL_STATE_PRESTADORA";
export const LOAD_FILTER_PRESTADORA = "LOAD_FILTER_PRESTADORA";

const PRESTADORA_API =  process.env.REACT_APP_URL_API + process.env.REACT_APP_PATH_PRESTADORA_API;

export function limpaDependenteOnChange(dependente, value = null) {
    return (dispatch) => {
      dispatch([change("prestadoraForm", dependente, value)]);
    };
}

export async function relacionamentoClienteFornecedor(value, field) {
    return (dispatch, getState) => {
        const selector = formValueSelector("prestadoraForm");
        const ENF = selector(getState(), 'empresaPrestadora.emitirNotaFiscal');
        const RP = selector(getState(), 'empresaPrestadora.realizarPagamento');

        const relacionamento = ENF ? (RP ? "Cliente e Fornecedor" : "Cliente") : (RP ? "Fornecedor" : null);

        dispatch(change("prestadoraForm", 'complemento.relacionamentoProtheus', relacionamento));
    };
}

export function getPrestadoras(filters, page = 1) {
    const filter = sereializeFieldsPrestadoraFilter(filters, page);
    return (dispatch) => {
        return axios
            .post(`${PRESTADORA_API}buscar`, { ...filter })
            .then(result =>
                dispatch([
                    change('prestadorFilterForm', 'pesquisou', true),
                    change('prestadorFilterForm', 'page', page),
                    {
                        type: PRESTADORA_FETCHED,
                        payload: result
                    }
                ])
            );
    };
}

export function exportPrestadoraExcel(filters, page = 1) {
    const params = sereializeFieldsPrestadoraFilter(filters, page);
  
    return (dispatch) => {
        return axios
            .post(`${PRESTADORA_API}exportar`, params)
            .then(result => {
                dispatch([
                    showAllMessages(result.messages),
                ])
  
                if (result.content) {
                  const { content } = result;
  
                  dispatch([
                    content ? downloadExcelFile(content?.arquivo, `${content?.nomeArquivo}.${content?.extensaoArquivo}`) : null,
                  ])
                }
            }); 
  
    }
  }

export function mudaDependenteOnChange(form = "prestadoraForm", dependente, value = null) {
    return (dispatch) => {
      dispatch([change(form, dependente, value)]);
    };
  }

export function cleanResult() {
    return (dispatch) => {
        return dispatch([
            initialize('prestadoraForm', { cnpj: '', nomeSimplificado: '', pesquisou: false }),
            { type: CLEAN_LIST_CONTENT },
        ]);
    }
}

export function cleanSearch() {
    return (dispatch) => {
        return dispatch([
            {
                type: INITIAL_STATE_PRESTADORA,
                payload: null
            }
        ]);
    }
}

export function editPrestadora(prestadora) {
    const filter = prestadora.filter || { cnpj: '', nomeSimplificado: '', page: 1, pesquisou: false }
    prestadora = deserializeFieldsPrestadoraForm(prestadora)

    return (dispatch) => {
        dispatch([
            {
                type: SAVE_FORM_PRESTADORA_ERRORS,
                payload: null
            },
        ])
        return axios
            .put(PRESTADORA_API, prestadora)
            .then(result =>
                dispatch([
                    showPrestadoraList(filter),
                    showAllMessages(result.messages),
                ])
            ).catch((err) => {
                if (err.response && err.response.data?.messages && err.response.data?.messages?.hasError) {
                    document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch([
                        {
                            type: SAVE_FORM_PRESTADORA_ERRORS,
                            payload: err.response.data?.messages
                        },
                    ])
                }
            })
    }
}

export function removePrestadora(idPrestadora, filter) {
    return (dispatch) => {
        return axios
            .delete(PRESTADORA_API + idPrestadora)
            .then(result =>
                dispatch([
                    showAllMessages(result.messages),
                    showPrestadoraDeleteDialog(false, 0),
                    showPrestadoraList(filter),
                ])
            ).catch(error => {
                dispatch(showPrestadoraDeleteDialog(false, 0))
            });
    }
}

export function showPrestadoraDeleteDialog(showDialog, idPrestadora, message) {
    return (dispatch) => {
        return dispatch([
            {
                type: PRESTADORA_SHOW_DELETE_DIALOG,
                payload: { showDialog, idPrestadora, message }
            },
        ])
    }
}

export function validateDeleteDialogMessage(idPrestadora) {
    return (dispatch) => {
        return axios.get(`${PRESTADORA_API}valida/${idPrestadora || ''}`)
        .then(result => {
            dispatch([
                {
                    type: LOAD_FORM_PRESTADORA,
                    payload: result.content
                },
                showPrestadoraDeleteDialog(true, idPrestadora, result.content)
            ])
        });
    }
}

export function loadFormPrestadora(filter, idPrestadora) {
    return dispatch => {
        return axios.get(`${PRESTADORA_API}tela/${idPrestadora || ''}`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FORM_PRESTADORA,
                        payload: result.content
                    },
                    serializeFieldsPrestadoraForm(result.content, filter)
                ])
            });
    };
}

export function loadFormFilterPrestadora() {
    return dispatch => {
        return axios.get(`${PRESTADORA_API}tela/filtro`)
            .then(result => {
                dispatch([
                    {
                        type: LOAD_FILTER_PRESTADORA,
                        payload: result.content
                    },
                ])
            });
    };
}

export function loadFormDocumento(documento, filter) {
    return dispatch => {
        return axios.post(`${PRESTADORA_API}verificar/${documento}`)
            .then(result => {
                const id = result.content?.empresaPrestadora?.id;

                if (id) {
                    dispatch(showPrestadoraForm(id, true))
                } else {
                    dispatch([
                      {
                          type: LOAD_FORM_PRESTADORA,
                          payload: {...result.content, empresaPrestadora: { statusAprovacao: { key: 1 } } }
                      },
                      serializeFieldsPrestadoraForm({...result.content, documento: formatStrToCnpjOrCpf(documento)}, filter, true)
                    ])
                }
            });
    };
}

export function changeEmailPrestadora(add, email, index) {
    return (dispatch, getState) => {
        if (email && typeof email == "string") {
            const selector = formValueSelector('prestadoraForm')
            let prestadoraList = selector(getState(), `empresaPrestadora.tiposServicosPrestadosSelecionados[${index}].tipoServicoPrestadoEmpresaEmails`);

            return dispatch([
                add ?
                arrayPush("prestadoraForm", `empresaPrestadora.tiposServicosPrestadosSelecionados[${index}].tipoServicoPrestadoEmpresaEmails`, email) :
                arrayRemove("prestadoraForm", `empresaPrestadora.tiposServicosPrestadosSelecionados[${index}].tipoServicoPrestadoEmpresaEmails`, prestadoraList.indexOf(email))
            ]);
        }
    }

}

export function onChangeServicoPrestado(value, indexNoForm) {
    return (dispatch, getState) => {
        const selector = formValueSelector('prestadoraForm')
        let prestadoraList = selector(getState(), 'empresaPrestadora.tiposServicosPrestadosSelecionados') || [];
        let listaOriginal = selector(getState(), 'servicosPrestados') || [];

        const marcado = value.target.checked;

        let newItem = {
            descricao: listaOriginal[indexNoForm].descricao,
            idTipoServicoPrestado: listaOriginal[indexNoForm].id,
            tipoServicoPrestadoEmpresaEmails: [],
        }

        return dispatch([
            marcado ?
                arrayPush("prestadoraForm", "empresaPrestadora.tiposServicosPrestadosSelecionados", newItem) :
                arrayRemove("prestadoraForm", "empresaPrestadora.tiposServicosPrestadosSelecionados", findIndex(prestadoraList, newItem))
        ]);
    }
}

export function showPrestadoraForm(idPrestadora, editAction) {
    let routeAction = NOVO_ROUTE_PATH;

    if (idPrestadora !== null) {
        routeAction = editAction ? EDITAR_ROUTE_PATH : DETALHAR_ROUTE_PATH;
        routeAction += `/${idPrestadora}`;
    }

    return dispatch => {
        return dispatch([
            history.push(`/${EMPRESA_PRESTADORA_ROUTE_PATH}/${routeAction}`),
            { type: '' }
        ]);
    };
}

export function showPrestadoraList(filter) {
    const cnpj = filter.cnpj || '';
    const nomeSimplificado = filter.nomeSimplificado || '';
    const pesquisou = filter.pesquisou || 1;
    const page = filter.page || false;

    const dataFilterForm = pesquisou ?
        { cnpj, nomeSimplificado, pesquisou } :
        { cnpj: '', nomeSimplificado: '' };

    return dispatch => {
        return dispatch([
            showConfirmDialog(false),
            initialize('prestadorFilterForm', { ...dataFilterForm }),
            pesquisou ? getPrestadoras({ cnpj, nomeSimplificado }, page) : undefined,
            history.push(`/${EMPRESA_PRESTADORA_ROUTE_PATH}`)
        ]);
    };
}

export function savePrestadora(prestadora) {
    const filter = prestadora.filter || { cnpj: '', nomeSimplificado: '', page: 1, pesquisou: false }
    prestadora = deserializeFieldsPrestadoraForm(prestadora);
    return (dispatch) => {
        dispatch([
            {
                type: SAVE_FORM_PRESTADORA_ERRORS,
                payload: null
            },
        ])
        return axios
            .post(PRESTADORA_API, prestadora)
            .then(result =>
                dispatch([
                    showPrestadoraList(filter),
                    showAllMessages(result.messages)
                ])
            ).catch((err) => {
                if (err.response && err.response.data?.messages && err.response.data?.messages?.hasError) {
                    document.getElementById('topContent').scrollTo({ top: 0, behavior: 'smooth' });
                    dispatch([
                        {
                            type: SAVE_FORM_PRESTADORA_ERRORS,
                            payload: err.response.data?.messages
                        },
                    ])
                }
            })
    }
}

export function pesquisarPrestadoraExistente(cnpj, idPrestadora) {
    return (dispatch) => {
        if (cnpj) {
            var filter = sereializeFieldsCnpjValidation(idPrestadora, cnpj)
            return axios.post(PRESTADORA_API + `consulta`, filter)
                .then(result =>
                    dispatch([
                        showAllMessages(result.messages)
                    ])
                );
        } else {
            return dispatch({ type: "" })
        }
    };
}


function findIndex(list, servico) {
    let indexresult = -1;

    list.forEach((item, index) => {
        if (item.idTipoServicoPrestado === servico.idTipoServicoPrestado) {
            indexresult = index;
        }
    });

    return indexresult;
}

export function getCEP(cep, estados) {
    return (dispatch) => {
        if (cep?.length == 9) {
            return axios.get(`${process.env.REACT_APP_URL_CORREIOS}/${cep}`).then((result) => {
                
                if (!result?.cep) toastr.error("Erro", "Endereço não encontrado na base");

                dispatch([
                    change("prestadoraForm", "endereco.endereco", result?.address || null),
                    change("prestadoraForm", "endereco.bairro", result?.district || null),
                    change("prestadoraForm", "endereco.cidade", result?.city?.name || null),
                    change("prestadoraForm", "endereco.estado", result?.state?.acronym ? estados.find(estado => estado.raw === result?.state?.acronym) : null),
                ])
            })
        }
    }
}
