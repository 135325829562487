import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, formValueSelector, Field, change } from "redux-form";

import Button from "../../../../../common/button/Button";
import EventLogbookModal from "../../../../gestao/pages/diarioBordo/modal/EventLogbookModal";
import Input from "../../../../../common/input/Input";

import {
  closeModalInserirNomePastaCedoc,
  changeCedocId,
} from "../../../../../../actions/fundo/FundoAction";

let FundoInserirNomePastaCedocModal = (props) => {
  const {
    changeCedocId,
    nomePasta,
    cedocOriginal,
    ultimoVinculo,
  } = props;

  return (
    <EventLogbookModal
      open={props.modalSolicitacaoParticipacao.showDialog}
      titleClass="title-modal-cedoc"
      className="container-modal-cedoc"
      disableBackdropClick
    >
      <div className="card-cedoc-header align-items-center">
        <div className="col more-fields">
          <div>
            <div>
              <h2>Vincular Pasta no CEDOC</h2>
              <h3 className="bold-label">
                ID da Pasta do Patrimônio Separado no CEDOC
              </h3>
              <Field
                component={Input}
                inputId="idCedoc"
                name="idCedoc"
                type="text"
                maxLength={90}
                // placeholder={"CRA_E0000_S000_000-000_XXXXXX"}
                filter="contains"
                params={{
                  form: "fundoForm",
                  field: "idCedoc",
                  currentValue: props.nomePasta,
                }}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <Button
                activeClassName={"col-lg-auto"}
                buttonStyle={"cancel"}
                action={() => props.closeModalInserirNomePastaCedoc(ultimoVinculo, cedocOriginal)}
                name="Fechar"
              />
            </div>
            <div className="col-md-4">
              <Button
                activeClassName={"col-lg-auto"}
                buttonStyle={"success"}
                action={() => changeCedocId(nomePasta)}
                name="Vincular"
              />
            </div>
          </div>
        </div>
      </div>
    </EventLogbookModal>
  );
};

FundoInserirNomePastaCedocModal = reduxForm({
  form: "fundoForm",
  destroyOnUnmount: false,
})(FundoInserirNomePastaCedocModal);
const selectorFundo = formValueSelector("fundoForm");
const mapStateToProps = (state) => {
  return {
    modalSolicitacaoParticipacao: state.fundoReducer.showInserirNomePastaCedocDialog,
    nomePasta: selectorFundo(state, "cedoc.nomePastaCedoc"),
    fundo: selectorFundo(state, "id"),
    cedocOriginal: state.fundoReducer.fundoFormTypes?.fundo?.idCedoc,
    ultimoVinculo: state.fundoReducer.fundoFormTypes?.ultimoVinculo
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModalInserirNomePastaCedoc,
      changeCedocId,
      change,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundoInserirNomePastaCedocModal);
