import { initialize } from 'redux-form';

import { getArrayDeserialized } from './../../../../utils/helpers/ArrayHelper';

import { 
    getStringBooleanValue,
    getBooleanValueFromString
} from "./../../../../utils/helpers/BooleanHelper";

import { 
    convertCurrencyToNumber,
    formatPercent,
    formatPercentToNumber,
    formatDecimalToNumber,
} from "./../../../../utils/helpers/NumberHelper";

import { 
    formatCNPJ,
    formatCNPJToString,
} from "./../../../../utils/helpers/StringHelper";
import { extractFromObj } from '../../../../utils/helpers/ObjectHelper';

export function serializeFieldsAtivoForm(ativo) {
    let ativoSerialized = {};
    
    if(ativo) {
        ativoSerialized = {
            ...ativo,
            percentualCedido: formatPercent(ativo.percentualCedido),
            empresaAuditora: ativo.empresaAuditora && {
                ...ativo.empresaAuditora,
                cnpj: formatCNPJToString(ativo.empresaAuditora.cnpj)
            },
            empresaServicer: ativo.empresaServicer && {
                ...ativo.empresaServicer,
                cnpj: formatCNPJ(ativo.empresaServicer.cnpj)
            },
            codigoB3CetipList: ativo.codigoB3CetipList && ativo.codigoB3CetipList.map(codigo => ({
                ...codigo,
                percentual: formatPercent(codigo.percentual)
            })),
            devedorAtivoList: ativo.devedorAtivoList && ativo.devedorAtivoList.map(devedor => ({
                ...devedor,
                devedorAuditado: getStringBooleanValue(devedor.devedorAuditado)
            })),
            originadorAtivoList: ativo.originadorAtivoList && ativo.originadorAtivoList.map(originadorAtivo => ({
                ...originadorAtivo,
                originador: {
                    ...originadorAtivo.originador
                }
            })),
            codigoB3CetipDesvinculadoList: [],
        }
    } else {
        ativoSerialized = {
            pagamento: {
                curvaPagamentoList: [],
                mesAtualizacaoPagamentoList: [],
            },
            ativoObjetoImovelList: [],
            ativoObjetoProdutoAgropecuarioList: [],
            cobrancaFechamentoAtivo: {
                contatoCobrancaList: []
            },
            codigoB3CetipList: [],
            devedorAtivoList: [],
            originadorAtivoList: []
        };
    }
    
    return initialize("ativoForm", ativoSerialized);
}

export function deserializeFieldsAtivoForm(ativo) {
    return {
        ...ativo,
        cnpj: ativo.cnpj && formatCNPJToString(ativo.cnpj),
        razaoSocial: ativo.razaoSocial && extractFromObj(ativo.razaoSocial, "razaoSocial"),
        percentualCedido: formatPercentToNumber(ativo.percentualCedido),
        ativoObjetoImovelList: getArrayDeserialized(ativo.ativoObjetoImovelList).map(objImovel => ({
            ...objImovel,
            areaTotal: formatDecimalToNumber(objImovel.areaTotal),
            areaTotalConstruida: formatDecimalToNumber(objImovel.areaTotalConstruida),
            areaPrivadaAbl: formatDecimalToNumber(objImovel.areaPrivadaAbl),
            valorAvaliacao: convertCurrencyToNumber(objImovel.valorAvaliacao)
        })),
        pagamento: ativo.pagamento && {
            ...ativo.pagamento,
            taxaSpread: formatPercentToNumber(ativo.pagamento.taxaSpread),
            percentualRemuneracaoFlutuante: formatPercentToNumber(ativo.pagamento.percentualRemuneracaoFlutuante),
        },
        cedente: ativo.cedente && {
            ...ativo.cedente,
            cnpj: formatCNPJToString(ativo.cedente.cnpj)
        },
        empresaAuditora: ativo.empresaAuditora && {
            ...ativo.empresaAuditora,
            cnpj: formatCNPJToString(ativo.empresaAuditora.cnpj)
        },
        empresaServicer: ativo.empresaServicer && {
            ...ativo.empresaServicer,
            cnpj: formatCNPJToString(ativo.empresaServicer.cnpj)
        },
        codigoB3CetipList: getArrayDeserialized(ativo.codigoB3CetipList).map(codigo => ({
            ...codigo,
            percentual: formatPercentToNumber(codigo.percentual),
            valorEmissaoAtual: convertCurrencyToNumber(codigo.valorEmissaoAtual),
        })),
        devedorAtivoList: getArrayDeserialized(ativo.devedorAtivoList).map(devedor => ({
            ...devedor,
            devedorAuditado: getBooleanValueFromString(devedor.devedorAuditado),
            cnpj: devedor.cnpj && formatCNPJToString(devedor.cnpj),
            descricao: devedor.descricao && extractFromObj(devedor.descricao, "razaoSocial")
        })),
        originadorAtivoList: getArrayDeserialized(ativo.originadorAtivoList).map(originadorAtivo => ({
            ...originadorAtivo,
            originador: {
                ...originadorAtivo.originador,
                cnpj: originadorAtivo.originador && formatCNPJToString(originadorAtivo.originador.cnpj),
                razaoSocial: originadorAtivo.originador && extractFromObj(originadorAtivo.originador.razaoSocial, "razaoSocial")
            }
        }))
    };
}

export function deserializeAtivosVinculados(ativos, idOperacao) {
    let vinculoAtivos = [];

    if(ativos && ativos.length > 0) {
        vinculoAtivos = [
            {
                idOperacao: idOperacao,
                idAtivo: ativos[0].idAtivo,
                ativoCodigoB3Cetips: ativos.map(item => ({ id: item.id, codigo: item.codigo })),
            }
        ]
    } else {
        vinculoAtivos = [
            {
                idOperacao: 0,
                idAtivo: 0,
                ativoCodigoB3Cetips: [],
            }
        ]
    }

    return vinculoAtivos;
}