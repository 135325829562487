import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector, Field } from "redux-form";

import { showConfirmDialog } from "../../../../../actions/common/modal/ConfirmDialogAction";
import {
  showUsuarioList,
  onChangeConta,
  changeConta,
  onChangeUsuario,
} from "../../../../../actions/usuario/UsuarioAction";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";
import Input from "../../../../common/input/Input";
import { actionRemessa, sairDaPagina } from "../../../../../actions/remessa/RemessaAction";
import Button from "../../../../common/button/Button";
import { withRouter } from "react-router-dom";
import ModalAction from "../modals/ModalAction";
import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { getDateFormatedFromString } from "../../../../../utils/helpers/DateTimeHelper";
import TableEmptyData from "../../../../common/table/TableEmptyData";
import TableHeader from "../../../../common/table/TableHeader";
import TableContent from "../../../../common/table/TableContent";
import Table from "../../../../common/table/Table";
import MaskedInput from "../../../../common/input/MaskedInput";
import { formatCNPJ } from "../../../../../utils/helpers/StringHelper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { withStyles } from "@material-ui/core/styles";
import DropzoneBase from "../components/dropZoneBase";
import DropzoneAnexos from "../components/dropZoneAnexos";
import ReactTooltip from "react-tooltip";

const styles = (theme) => ({
  parent: {
    width: "100%",
    marginTop: "0.5%",
    backgroundColor: "transparent",
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: "#630A37",
    "&:disabled": {
      color: "#D0D0D0",
    },
  },
  buttons: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "1rem !important",
  },
  stepper: {
    backgroundColor: "transparent",
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: "flex",
      justifyContent: "center",
    },
    "& [class*='MuiTypography-root']": {
      width: "max-content",
      padding: "0 10px",
      borderRadius: "20px",
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: "#B8B8B8",
        color: "#121212c9",
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: "#FFC107",
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: "#83BF74",
        color: "#ffffff",
        "&.revisao": {
          backgroundColor: "#cc0000",
        },
      },
    },
  },
});

let RemessaForm = (props) => {
  const {
    title,
    handleSubmit,
    sairDaPagina,
    remessa,
    itemList,
    status,
    classes,
    readOnly,
  } = props;

  const [action, setAction] = React.useState("");

  const INICIADO = status?.key == 1;
  const ANALISADO = status?.key == 3;

  const data = {
    id: props.match.params?.idRemessa,
    remessa,
    action
  }

  const tableHeader = (
    <tr>
        <th className='text-center fw-700 pb-2'>LINHA</th>
        <th className='text-center fw-700 pb-2'>CONTAS A PAGAR</th>
        <th className='text-center fw-700 pb-2'>CONTRATO</th>
        <th className='text-center fw-700 pb-2'>CNPJ FORNECEDOR</th>
        <th className='text-center fw-700 pb-2'>ARQUIVO 1</th>
        <th className='text-center fw-700 pb-2'>ARQUIVO 2</th>
        <th className='text-center fw-700 pb-2'>DATA DE EMISSÃO</th>
        <th className='text-center fw-700 pb-2'>DATA DE PAGAMENTO</th>
        <th className='text-center fw-700 pb-2'>VALOR BRUTO</th>
        <th className='text-center fw-700 pb-2'>ANÁLISE</th>
    </tr>
  );

  const tableContent = (
    itemList?.length > 0 
        ? itemList.map((item, index) =>
            (<tr key={index}>
                <td className='text-center'>{item?.linha || '--'}</td>
                <td className='text-center'>{item?.contasPagar || '--'}</td>
                <td className='text-center'>{item?.contrato || '--'}</td>
                <td className='text-center'>{item?.cnpj ? formatCNPJ(item?.cnpj) : '--'}</td>
                <td className='text-center'>{item?.arquivo1 || '--'}</td>
                <td className='text-center'>{item?.arquivo2 || '--'}</td>
                <td className='text-center'>{item.dataEmissao ? getDateFormatedFromString(item?.dataEmissao) : '--'}</td>
                <td className='text-center'>{item.dataPagamento ? getDateFormatedFromString(item?.dataPagamento) : '--'}</td>
                <td className='text-center'>{item?.valorBruto ? convertCurrencyNumber(item?.valorBruto, "R$", 2) : '--'}</td>
                <td className='text-center'>
                  <div
                    data-type="opea"
                    data-for={`ttp-${item.id}`}
                    data-tip={item?.analise || '--'}
                  >
                    <p className="clamp-3 m-0">{item?.analise || '--'}</p>
                    {(item?.analise) && (
                      <ReactTooltip
                        effect="solid"
                        className="px-2 py-1"
                        globalEventOff="click"
                        id={`ttp-${item.id}`}
                      />
                    )}
                  </div>
                </td>
            </tr>)
        )
        : <TableEmptyData message={"Sem dados a serem exibidos"} />
  );

  const statusValue = remessa?.status?.key;
  const STS_CANCELADO = statusValue == 5;

  let renderStatus = [
    {
      key: 1,
      value: "Iniciado",
      raw: "Iniciado",
      show: true,
      concluidos: [1],
    },
    {
      key: 2,
      value: "Processando",
      raw: "Processando",
      show: true,
      concluidos: [1, 2],
    },
    {
      key: 3,
      value: "Analisado",
      raw: "Analisado",
      show: true,
      concluidos: [1, 2, 3],
    },
    {
      key: 4,
      value: "Concluído",
      raw: "Concluido",
      show: !STS_CANCELADO,
      concluidos: [1, 2, 3, 4],
    },
    {
      key: 5,
      value: "Cancelado",
      raw: "Cancelado",
      show: STS_CANCELADO,
      concluidos: [1, 2, 3, 4],
    },
];

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value);
  const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos);

  const moedaSimbolo = "R$";

  function CustomStepIcon({ active, completed, classes }) {
    const emRevisao = classes?.completed?.includes("revisao");
    const cRevi = "#cc0000";
    const cComp = "#83BF74";
    const cActv = "#FFC107";
    const cDflt = "#888888";

    return emRevisao ? (
      <RadioButtonChecked style={{ color: cRevi }} />
    ) : completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    );
  }

  return (
    <Form>
      {remessa && (
        <>
          <FormHeader title={title} col={"col-lg"} offSet={20}></FormHeader>
        
          <Stepper
            className="my-3"
            classes={{ root: classes.stepper }}
            activeStep={STS_CANCELADO ? null : concluidos.length || 0}
            alternativeLabel
          >
            {steps.map((step, index) => (
                      <Step
                        key={index}
                        completed={concluidos.some((et) => et === index + 1)}
                      >
                        <StepLabel
                          completed={concluidos.some((et) => et === index + 1)}
                          className={classes.customStepLabel}
                          StepIconComponent={CustomStepIcon}
                          classes={{
                            completed: `${classes.completed} ${
                              STS_CANCELADO && index + 1 == 4 && "revisao"
                            }`,
                          }}
                          StepIconProps={{
                            classes: {
                              completed: `${classes.completed} ${
                                STS_CANCELADO && index + 1 == 4 && "revisao"
                              }`,
                              active: classes.active,
                              disabled: classes.disabled,
                            },
                          }}
                        >
                          {step}
                        </StepLabel>
                      </Step>
            ))}
          </Stepper>
                          
          <FormContent handleSubmit={handleSubmit}>
            {(itemList?.length > 0) && (
              <div className="shadow-custom rounded-12 bg-white px-3 py-2 column">
                <div className="row px-0">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="quantidade"
                      name="quantidade"
                      label="Quantidade"
                      readOnly={true}
                    />
                  </div>
          
                  <div className="col-lg">
                    <Field
                      component={MaskedInput}
                      inputId="valorTotal"
                      name="valorTotal"
                      label="Valor Total"
                      prefix={`${moedaSimbolo} `}
                      readOnly={true}
                      thousandSeparator={'.'}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                </div>
              </div>
            )}
          
            {itemList?.length <= 0 && (
              <>
                <div className="shadow-custom rounded-12 bg-white mb-4 p-4 column">
                  <span className="h3 page-head__title">Base de Pagamentos</span>
                  <DropzoneBase />
                </div>
          
                <div className="shadow-custom rounded-12 bg-white mb-4 p-4 column">
                  <span className="h3 page-head__title">Anexos</span>
                  <DropzoneAnexos />
                </div>
              </>
            )}
          
            {(itemList?.length > 0) && (
              <Table
                withoutHeader
                classesOuter="tb-remessa"
                fileLabel="Exportar Arquivo"
                classes="col-12 shadow-custom rounded-12 bg-white py-0 my-4 mx-0 mb-2"
              >
                  <TableHeader >
                      {tableHeader}
                  </TableHeader>
                  <TableContent>
                    {tableContent}
                  </TableContent>
              </Table>
            )}
          
            <div className="col-12 px-0">
              <div className="d-flex flex-row flex-wrap justify-content-between align-items-center col-12 p-0 mb-2 mt-3">
                <Button
                  name="Voltar Tela"
                  classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-lg-3 mr-2 mb-2"
                  action={() => sairDaPagina()}
                />
          
                {(itemList?.length > 0 && ANALISADO) && (
                  <div className="d-flex flex-row flex-wrap justify-content-lg-between align-items-center">
                    <Button
                      name={"Cancelar Remessa"}
                      classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-lg-3 mr-2 mb-2"
                      action={() => setAction("cancelar")}
                    />
          
                    <Button
                      name={"Reprocessar Arquivo"}
                      classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mr-lg-3 mr-2 mb-2"
                      action={() => setAction("reprocessar")}
                    />
          
                    <Button
                      name={"Criar Pedidos"}
                      classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0 mb-2"
                      action={() => setAction("criar")}
                    />
                  </div>
                )}
          
                {INICIADO && (
                  <Button
                    name={"Processar Arquivo"}
                    classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                    action={() => setAction("importar")}
                  />
                )}
              </div>
            </div>
          </FormContent>
                        
          {action && (
            <ModalAction
              open={Boolean(action)}
              close={() => setAction("")}
              item={data}
            />
          )}
        </>
      )}
    </Form>
  );
};

// VALIDAÇÃO
const validate = (values) => {
  const errors = {};
  return errors;
};

const selector = formValueSelector("RemessaForm");

const mapStateToProps = (state) => ({
  idTipoItem: selector(state, "idTipoItem") || null,
  lista: selector(state, "lista") || null,
  remessa: state.remessaReducer.remessaForm || null,
  status: state.remessaReducer.remessaForm?.status || null,
  itemList: state.remessaReducer.remessaForm?.itemList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showUsuarioList,
      showConfirmDialog,
      onChangeConta,
      changeConta,
      onChangeUsuario,
      sairDaPagina,
      actionRemessa,
    },
    dispatch
  );

const ConnectedRemessaForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RemessaForm))
);

export default reduxForm({
  form: "RemessaForm",
  validate,
})(ConnectedRemessaForm);
